module controllers {
    export module tariff {
        interface IProductTariffScope extends ng.IScope {
            IsLoading: boolean;
            ShowGrid: boolean;
        }

        interface IProductTariffParams extends ng.ui.IStateParamsService {
            entityCode: string;
        }

        export class productTariffListCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'tariffPriorityService',
                'productTypeService',
                'classificationValueService',
                'tariffCountryService',
                'productTariffService',
                'lookupService',
                '$stateParams'
            ];

            tariffSearch: interfaces.tariff.IProductTariffSearch = {
                TariffStatusId: 0,
                OwnerEntityId: 0,
                SimulationCountryId: 249
            };

            
            Description = "Product Tariffing List Views"

            tariffSettings: interfaces.tariff.ITariffSetting;

            searchAccordian: boolean = false;
            searchSimulationAccordian: boolean = false;

            fromDateOpen: boolean = false;
            toDateOpen: boolean = false;
            simulationDateOpen: boolean = false;
            simulationDate: moment.Moment = moment.utc();
            selectedCategories: string = "";
            selectedCountry: interfaces.applicationcore.IDropdownModel;

            items: string[];
            entity: string;
            currentEntity: interfaces.applicationcore.IEntity;

            dropdownsObject = {
                Entities: Array<interfaces.applicationcore.IEntity>()
            };

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            }; 

            statusList: interfaces.applicationcore.IDropdownModel[] = [];
            priorityList: interfaces.applicationcore.IDropdownModel[] = [];

            apiList: uiGrid.IGridApi;
            selected: uiGrid.IGridRow;

            selectedRowsArray: Array<interfaces.tariff.IProductTariffDisplay> = [];

            filterNames: string[] = ["OwnerEntityName", "Code", "ShortDescription",
                "PriorityCode", "Commodity", "StatusCode",
                "CountryOfOriginCode", "Group", "Department"];
            filterList: interfaces.applicationcore.IKeyValue[] = [];

            sortName: string;
            sortDirection: string;

            constructor(
                private $scope: IProductTariffScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public tariffPriorityService: services.master.tariffPriorityService,
                public productTypeService: services.master.productTypeService, 
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public tariffCountryService: interfaces.tariff.ITariffCountryService,
                public productTariffService: services.tariff.productTariffService,
                public lookupService: interfaces.applicationcore.ILookupService,
                private $stateParams: IProductTariffParams,
            ) {
                if ($stateParams.Items)
                {
                    this.items = $stateParams.Items;
                    this.entity = $stateParams.Entity;
                }

                this.loadControls();  

                //Reloads  after an update to product Tariff
                $transitions.onSuccess({
                    to: 'auth.ProductTariff',
                    from: 'auth.ProductTariff.Update.**'
                }, () => {
                    this.search_click();
                    
                    });      

                $transitions.onSuccess({
                    to: 'auth.ProductTariff',
                    from: 'auth.ProductTariff.MultipleProductTariff.**'
                }, () => {
                   
                    this.search_click();

                });    

            }

            loadControls() {
                let controlPromises = [
                    this.loadEntities(),this.loadStatuses(), this.loadTariffCountry(), this.loadSelectedCountry(), this.loadTariffSettings()
                ]
                
                this.$q.all(controlPromises).then((data) => {
                    this.tariffSearch.SimulationDate = this.simulationDate;
                   this.$scope.ShowGrid = true;
                   this.DoSearch();                   
                });

            }

            loadEntities() {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getList().query((successList) => {                    
                    this.dropdownsObject.Entities = successList;                   
                    deferred.resolve(true);
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            loadTariffPriority() {
                if (this.$rootScope.Entity.Id) {
                    return this.tariffPriorityService.getDropdownList().query({ ownerEntityId: this.$rootScope.Entity.Id }, () => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }
                else {
                    return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                        this.currentEntity = resultEntity;
                        return this.tariffPriorityService.getDropdownList().query({ ownerEntityId: resultEntity.Id }, () => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                }
            }

            loadTariffCountry() {
                return this.tariffCountryService.getDropdownList().query({ }, () => {
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadSelectedCountry() {
                if (this.$rootScope.Entity)
                {
                    this.entityService.getEntityCountry().get({ entityId: this.$rootScope.Entity.Id }, (result: interfaces.applicationcore.ICountry) => {
                        this.selectedCountry = <interfaces.applicationcore.IDropdownModel>{
                            Id: result.Id,
                            Code: result.Code,
                            Description: result.Description,
                            Display: result.Code + " - " + result.Description,
                            Selected: true
                        };

                        this.tariffSearch.SimulationCountryId = this.selectedCountry.Id;
                    }).$promise;
                }
                else
                {
                    return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                        this.entityService.getEntityCountry().get({ entityId: resultEntity.Id }, (result: interfaces.applicationcore.ICountry) => {
                            this.selectedCountry = <interfaces.applicationcore.IDropdownModel>{
                                Id: result.Id,
                                Code: result.Code,
                                Description: result.Description,
                                Display: result.Code + " - " + result.Description,
                                Selected: true
                            };

                            this.tariffSearch.SimulationCountryId = this.selectedCountry.Id;
                        });
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }
            }

            loadTariffSettings() {

               return this.productTariffService.GetTariffSettings().get({}, (result: interfaces.tariff.ITariffSetting) => {
                  
                    this.tariffSettings = result;

                }).$promise;
            }
            
            loadProductTypes() {
                if (this.$rootScope.Entity.Id) {
                    return this.productTypeService.getDropdownList().query({ ownerEntityId: this.$rootScope.Entity.Id }, () => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }
                else {
                    return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                        return this.productTypeService.getDropdownList().query({ ownerEntityId: resultEntity.Id }, () => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                }
            }

            loadStatuses() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ProductTariffCountryStatus
                }, (resultList) => {
                    this.statusList = resultList;
                    }).$promise;
            }

            search_click() {
                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;
                this.searchSimulationAccordian = false;
                if (this.selectedCountry) { this.tariffSearch.SimulationCountryId = this.selectedCountry.Id; };
                
                this.selectedCategories = "";

                if (this.tariffSearch.ProductCategory){
                    for (let item of this.tariffSearch.ProductCategory) {
                        this.selectedCategories = this.selectedCategories + item.Id +  ","
                    }
                }
                this.tariffSearch.ProductCategoryList = this.selectedCategories;

                this.DoSearch();
            }

            showAll_click() {
                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;
                this.searchSimulationAccordian = false;
                this.tariffSearch = {}

                if (this.apiList !== undefined) {
                    this.apiList.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiList.grid.resetColumnSorting(null);
                        this.DoSearch();
                    });
                } else {
                    this.DoSearch();
                }
            }

            downloadToExcel() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.tariffSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiList) {

                    var grid = this.apiList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject,
                    items: this.items,
                    entityCode: this.entity,
                }

                this.productTariffService.getProductTariffListExcel(params).then(() => {
                    this.$scope.IsLoading = false;
                }, (data) => {
                    this.$scope.IsLoading = false;
                    this.gvwList.data = [];
                    this.gvwList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            DoSearch() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                
                angular.forEach(this.tariffSearch, (n, key) => {
                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiList) {
                    var grid = this.apiList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                if (this.currentEntity)
                    this.entity = this.currentEntity.Code;

                var params = {
                    connectSearch: searchObject,
                    numberRecords: this.paginationOptions.pageSize,
                    items: this.items,
                    entityCode: this.entity,
                    pageNumber: this.paginationOptions.pageNumber
                }

                this.productTariffService.getProductTariffList().query(params, (data) => {
                    this.$scope.IsLoading = false;
                    this.gvwList.data = data;
                    this.selectedRowsArray = [];

                    if (data[0]) {
                        this.gvwList.totalItems = data[0].NumberRecords;
                    } else {
                        this.gvwList.totalItems = 0;
                    }


                    this.$timeout(() => {
                        if (this.apiList)
                        {
                            this.getFilters();
                            this.setFilters(); 
                        }
                    });

                    
                    this.$timeout(() => {
                        if (this.selected) {
                            var index = _.findIndex(this.gvwList.data, (o) => { return o.Id === this.selected.entity.Id });
                            this.apiList.selection.selectRow(this.gvwList.data[index]);
                        }
                    });
                 

                }, (data) => {
                    this.$scope.IsLoading = false;
                    this.gvwList.data = [];
                    this.gvwList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            ListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            ListShortChange(gridApi) {
                this.DoSearch();
            }

            ListFilterChange(gridApi: uiGrid.IGridApi) {
                _.forEach(gridApi.grid.columns, (c) => {
                    if(!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if(keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });
                
                this.DoSearch();
            }

            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiList = gridApi;

                this.apiList.core.on.filterChanged(this.$scope, () => { this.ListFilterChange(gridApi) });
                this.apiList.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.ListShortChange(gridApi);

                    if (sortColumns[0])
                    {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else
                    {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });
                this.apiList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.apiList.core.on.rowsRendered(this.$scope, () => {

                    for (let selectedRow of this.selectedRowsArray) {
                       
                        for (var row of this.gvwList.data) {

                            if (selectedRow.Id === row.Id) {
                                this.apiList.selection.selectRow(row);
                            }
                        }
                    }
                });

                this.apiList.selection.on.rowSelectionChangedBatch(this.$scope,(selectedBatch:uiGrid.IGridRow[])=>{
                   

                    for (let selected of selectedBatch)
                    {
                        if (!selected.isSelected) {

                            for (let selectedRow of this.selectedRowsArray) {
                                if (selectedRow.Id === selected.entity.Id) {
                                    this.selectedRowsArray.splice(this.selectedRowsArray.indexOf(selectedRow), 1);
                                }
                            }
                        }
                        else {
                            var Exists: number = 0;
                            for (let selectedRow of this.selectedRowsArray) {
                                    if (selectedRow.Id === selected.entity.Id) {
                                        Exists = 1;
                                    }                           
                                }
                           
                            if (Exists === 0) {
                                this.selectedRowsArray.push(selected.entity);
                            }                        
                        } 
                    }
                } )

                this.apiList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.selected = selected;
                 
                    if (!selected.isSelected) {

                        for (let selectedRow of this.selectedRowsArray) {
                            if (selectedRow.Id === selected.entity.Id) {
                                this.selectedRowsArray.splice(this.selectedRowsArray.indexOf(selectedRow), 1);
                            }
                        }
                    }
                    else {
                        var Exists: number = 0;
                        for (let selectedRow of this.selectedRowsArray) {
                                if (selectedRow.Id === selected.entity.Id) {
                                    Exists = 1;
                                }                           
                            }
                       
                        if (Exists === 0) {
                            this.selectedRowsArray.push(selected.entity);
                        }                        
                    }   
                });

                this.$timeout(() => {
                    if (this.selected)
                        this.apiList.selection.selectRow(this.selected.entity);
                });

                this.apiList.cellNav.on.navigate(this.$scope, (newCol) => {
                    this.$timeout(() => {
                        this.apiList.selection.selectRow(newCol.row.entity);
                    });
                });
            }

            /*
             Id: number,
            OwnerEntityCode: string,
            Code?: string,
            ShortDescription?: string,
            PriorityCode?: string,
            PriorityDescription?: string,
            Commodity?: string,
            Status?: string,
            CountryOfOriginCode?: string,
            Group?: string,
            Department?: string
*/

            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.apiList.grid.getColumn(name).filters[0].term || this.apiList.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiList.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiList.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.apiList.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection)
                {
                    this.apiList.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }

            gvwList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: true,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                }, {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                            <div> 
                                <button type="button" ui-sref="auth.ProductTariff.Update({ tprId: row.entity.Id })" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "OwnerEntityName",
                    displayName: "Entity",
                    field: "OwnerEntityCode",
                    width: 60,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "Code",
                    displayName: "Code",
                    field: "Code",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                    name: "ShortDescription",
                    displayName: "Short Description",
                    field: "ShortDescription",
                    width: 300,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                }, {
                    name: "PriorityCode",
                    displayName: "Priority",
                    cellTemplate: `<div class="ui-grid-cell-contents" >{{row.entity.PriorityCode}} - {{row.entity.PriorityDescription}}</div>`,
                    width: 100,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                }, {
                    name: "Commodity",
                    displayName: "Commodity",
                    field: "Commodity",
                    width: 100,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                }, {
                    name: "StatusCode",
                    displayName: "Status",
                    field: "Status",
                    cellTemplate: `<div class="ui-grid-cell-contents" >{{row.entity.Status}}</div>`,
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "CountryOfOriginCode",
                    displayName: "Origin",
                    field: "CountryOfOriginCode",
                    width: 60,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                }, {
                    name: "Group",
                    displayName: "Group",
                    field: "Group",
                    width: 200,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                }, {
                    name: "Department",
                    displayName: "Department",
                    field: "Department",
                    width: 200,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                }
                ]
            };

            MultipleProductTariff_Click() {

                //var selectedRows: Array<interfaces.tariff.IProductTariffDisplay> = this.apiList.selection.getSelectedRows();

                var selectedRows = this.selectedRowsArray;

                if (!selectedRows || selectedRows.length <= 0) {
                    this.generalService.displayMessage("Please select a Product to Tariff", Enum.EnumMessageType.Warning);
                    return;
                }

                var productIds: Array<number> = selectedRows.map<number>((o: interfaces.tariff.IProductTariffDisplay) => {
                    return o.Id;
                });

                

                this.lookupService.openTariffLookup(undefined, Enum.EnumTariffCodeType.NationMeasure, true,null,this.$rootScope.Entity.Id,).result.then(
                    (result: interfaces.tariff.INationalMeasureCodeLookup) => {       

                        var productTariff = {
                            productId: 0,
                            countryId: result.CountryId,
                            codeTypeClassificationValueId: result.CodeTypeClassificationValueId,
                            tariffCode: result.NationalMeasureCode,
                            statusId: Enum.ProductTariffStatus.Active,
                            startDate: result.SimulationDate,//moment.utc(),
                            endDate: undefined
                        };

                        this.$state.go("auth.ProductTariff.MultipleProductTariff", { productIds: productIds, tariffCode: <interfaces.tariff.ITariffCode>productTariff });
                    }, (errorResult) => {

                    });
            }

            checkProductsSelected() {
                if (!this.apiList || this.apiList.selection.getSelectedRows().length < 1) {
                    this.generalService.displayMessage("Please select Product(s) to change status", Enum.EnumMessageType.Warning);
                }
            }

            MultipleProductTariffStatusChange(statusId:number,statusDisplay: string)
            {
                var selectedRows = this.selectedRowsArray;

                if (!selectedRows || selectedRows.length <= 0) {
                    this.generalService.displayMessage("Please select Product(s) to change status", Enum.EnumMessageType.Warning);
                    return;
                }

                var productIds: Array<number> = selectedRows.map<number>((o: interfaces.tariff.IProductTariffDisplay) => {
                    return o.Id;
                });



                this.generalService.displayConfirmationBox("Tariff Status Change", "Change Product Tariff Status to "+statusDisplay +"?").then((result) => {
                    if (result) {
                        this.$scope.IsLoading = true;
                        this.productTariffService.MultipleProductTariffStatusChange(productIds, statusId).save({ }, (data: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(data);                   
                            this.$scope.IsLoading = false;
                            this.apiList.selection.clearSelectedRows();
                            this.selectedRowsArray = [];
                            this.DoSearch()
        
                        }, (errorResponse) => {
                            this.$scope.IsLoading = false;
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        });
                        
                    }
                }, () => { });
            }

                
        };

        angular.module("app").controller("productTariffListCtrl", controllers.tariff.productTariffListCtrl);
    }
}